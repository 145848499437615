/* #root {
  max-width: 1280px;
  margin: 0 auto;
  padding: 2rem;
  text-align: center;
} */

html {background: #15141c; background-color: #15141c}
.App {
  transition: all 0.5s ease;
}



.logo {
  height: 6em;
  padding: 1.5em;
  will-change: filter;
  transition: filter 300ms;
}

.logo:hover {
  filter: drop-shadow(0 0 2em #646cffaa);
}

.logo.react:hover {
  filter: drop-shadow(0 0 2em #61dafbaa);
}

@keyframes logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

@media (prefers-reduced-motion: no-preference) {
  a:nth-of-type(2) .logo {
    animation: logo-spin infinite 20s linear;
  }
}

.card {
  padding: 2em;
}

.read-the-docs {
  color: #888;
}

/* 26Feb23 */

.warning {
  max-width: 900px;
  background: #f1f1;
  padding: 10px 20px;
  border-radius: 5px;
  color: #d52323;
  margin: 0 auto;
  gap: 10px;
  display: flex;
  place-content: center;
}

input[type="password"].rpv-core__textbox {
  border: solid 2px rgb(237, 237, 237);
  border-radius: 5px;
  background-color: rgb(255, 255, 255);
  box-shadow: 0px 3px 70px 0px rgb(0 0 0 / 7%);
  height: 45px;
  width: 100%;
  font-size: 15px;
  border: 1px solid #d1d5db;
  box-shadow: none;
  transition: 0.5s ease;
  color: rgb(137, 137, 137);
  padding: 15px;
  max-width: 500px;
  margin-bottom: 10px;
}
input.rpv-core__textbox:focus-visible {
  outline: none;
}
* {
  padding: 0;
  margin: 0;
}

body,
html {
  height: 100%;
}

/* body:after {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  background-image: url("https://templates.seekviral.com/rifmaup/forms/LoginSignup_Form/assets/images/body-bg-right.png");
  background-repeat: no-repeat;
  width: 315px;
  background-size: cover;
  height: 100%;
  z-index: 0;
} */

body {
  position: relative;
}

button.rpv-core__primary-button {
  background-color: rgb(81, 56, 238);
  width: inherit;
  border-radius: 5px;
  border: solid 2px transparent;
  font-size: 16px;
  color: rgb(249, 249, 250);
  font-weight: bold;
  overflow: hidden;
  position: relative;
  display: inline-block;
  min-height: 45px;
  max-width: max-content;
  margin: 10px auto 0;
  display: grid;
  place-content: center;
}
button:hover {opacity: 0.9 !important}
.form {
  background: #f5f5f5;
  max-width: 570px;
  margin: 0 auto;
  padding-block: 40px;
  border-radius: 10px;
  width: 100%;
}

.btn-group {
  margin-top: 20px;
}

.form-area {
  min-height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
}

.overlays_parent .overlays::after,
.screen-disable::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  
  backdrop-filter: blur(4px);
  
  z-index: 9;
  color: white;

/*background-color: rgba(0, 0, 0, 0.93);*/
   background: rgb(0,0,0);
background: -moz-linear-gradient(324deg, rgba(0,0,0,0.95) 6%, rgba(153,16,75,1) 100%);
background: -webkit-linear-gradient(324deg, rgba(0,0,0,0.95) 6%, rgba(153,16,75,1) 100%);
background: linear-gradient(324deg, rgba(0,0,0,0.95) 6%, rgba(153,16,75,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#99104b",GradientType=1); 
/*  transition: all 0.5s ease;*/
}
/* .screen-disable .not-active-page-hover-message {display: none;} */
.applay_overlays {
  position: relative;
}

.not-active-page-hover-message {
  display: block;
  position: absolute;
  top: 50%;
  text-transform: uppercase;
  font-weight: 600;
  left: 50%;
  color: #fff;
  transform: translate(-50%, -50%);
  z-index: 99999;
  text-align:center;
  font-size:2vw !important;
  border-radius: 5px !important;
  border:1px solid #992861;
  padding: 10px 20px;
  cursor: default;
}
.screen-disable .not-active-page-hover-message {
  display: none !important;
}

div#restrictedmsg button {
  text-transform: uppercase;
  background: #3e3e3e;
  text-align: right;
  color: #fff;
  padding: 4px 10px;
  border-radius: 5px;
  font-size: 14px;
  font-weight: 600;
  border: 2px solid #575757;
  display: flex;
  align-items: center;
  gap: 5px;
  margin-left: 80px;
  margin-top: 20px;
}
div#restrictedmsg p {
  text-align: center;
  background: #fff;
  color: #000;
  text-transform: uppercase;
  font-weight: 700;
  max-width: max-content;
  padding: 10px 20px;
  margin: 0px auto;
  z-index: 999;
  display: flex;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  background: #ffcece;
  border: 1px dashed #f00;
}

div#restrictedmsg {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999;
}

@media print {
  .block_print {
    display: none !important;
    visibility: hidden !important;
  }
  /* html, body {
    display:none !important;
    visibility: hidden !important;
    } */
}

.center-menu {
  display: flex;
  justify-content: center;
  max-width: calc(100% - 200px);
  width: 100%;
}
.test01 svg.rpv-core__icon {
  width: 26px;
}

.black-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  
/*  transition: all 0.1s;*/
  display: flex;
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(4px);
  z-index:10;

/*  background-color: rgba(0, 0, 0, 0.93);*/
   background: rgb(0,0,0);
  background: -moz-linear-gradient(324deg, rgba(0,0,0,0.95) 6%, rgba(153,16,75,1) 100%);
  background: -webkit-linear-gradient(324deg, rgba(0,0,0,0.95) 6%, rgba(153,16,75,1) 100%);
  background: linear-gradient(324deg, rgba(0,0,0,0.95) 6%, rgba(153,16,75,1) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000",endColorstr="#99104b",GradientType=1); 

}

button.password_field {
  background-color: #ab3d75;
  width: inherit;
  border-radius: 5px;
  border: solid 2px transparent;
  font-size: 16px;
  color: rgb(249, 249, 250);
  font-weight: bold;
  overflow: hidden;
  position: relative;
  display: inline-block;
  min-height: 45px;
  min-width: 100px;

  max-width: max-content;
  margin: 10px auto 0;
  display: grid;
  place-content: center;
}
.overlays_parent .not-active-page-hover-message {
  display: block !important;
}
.screen-disable .not-active-page-hover-message {
  display: none !important;
}

.btn-svg-icon {max-width: 20px;}
/* temp need to remove later */
/* .rpv-core__text-layer-text{
  pointer-events: none;
} */

.contentDesc {color: #ffffffd4;}

.contentHeading {font-size: 1.85vw; line-height: 2vw;}
