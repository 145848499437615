/* style.css */

.alert-container {
    position: relative;
    display: flex;
    align-items: center;
    background-color: #ffe3e3;
    border: 1px solid #ff0000;
    border-radius: 8px;
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: box-shadow 0.3s ease-in-out;
}
/* MP3Viewer.css */

/* MP3Viewer.css */

.file-viewer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
  }
  
  .mp3-viewer {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .music-icon {
    margin-bottom: 20px; /* Adjust as needed */
  }
  
.alert-icon {
    fill: none;
    stroke: #ff0000;
    stroke-width: 2px;
    width: 24px;
    height: 24px;
    flex-shrink: 0;
    margin-right: 16px;
    transition: stroke 0.3s ease-in-out;
}

.alert-content {
    display: flex;
    flex-direction: column;
}

.alert-title {
    color: #ff0000;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px;
    text-transform: uppercase;
    transition: color 0.3s ease-in-out;
}

.alert-message {
    color: #4f4f4f;
    line-height: 1.5;
}

.alert-container:hover {
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.alert-container:hover .alert-icon {
    stroke: #ff5252;
}

.alert-container:hover .alert-title {
    color: #ff5252;
}

.alert-container:focus {
    outline: none;
    box-shadow: 0 0 0 2px #ff5252;
}

.alert-container:focus .alert-icon {
    stroke: #ff5252;
}

.alert-container:focus .alert-title {
    color: #ff5252;
}

/* Add this CSS in a separate CSS file or in your component's styles */
.file-table-container {
    margin: 20px;
}

.file-table {
    width: 100%;
    border-collapse: collapse;
    font-family: Arial, sans-serif;
}

.file-table th,
.file-table td {
    border: 1px solid #ccc;
    padding: 12px;
    text-align: left;
}
.container_image {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
    box-sizing: border-box;
    padding: 20px;
}

.img-responsive {
    max-width: 100%;
    max-height: 100%;
    display: block;
}

.file-table th {
    background-color: #f2f2f2;
}

.file-info {
    display: flex;
    align-items: center;
}

.file-icon {
    height: 20px;
    width: 20px;
    margin-right: 10px;
}

.file-name {
    font-weight: bold;
}

.video-frame {
    display: block;
    margin: 0 auto;
    max-width: 100%;
    width: 100%;
    height: auto;
}
.rpv-core__progress-bar-progress {

    background-color: #992861 !important;
}
.fs18{
    font-size: 18px !important;
    font-weight: 600 !important;
}
.fs16{
    font-size: 16px !important;
    font-weight: 400 !important;
}
/* styles.css or any other relevant css file */
.customIconSize2 {
    width: 25px !important;
    height: 25px !important;
  }
  .customIconSize {
    width: 30px !important;
    height: 30px !important;
  }
  
.download-button {
    padding: 0;
    margin-right: 5px;
    /* Remove padding */
    color: #992861;
    border: none;
    border-bottom: 1px solid #992861;
    /* Add bottom border for underline effect */
    border-radius: 0;
    /* Remove border radius */
    cursor: pointer;
    box-shadow: none;
    /* Remove box shadow */
    transition: border-color 0.3s ease;
    /* Add border-color transition */
    text-decoration: none;
    /* Remove default text decoration */
    background-color: transparent;
    /* Make background transparent */
}




.file-viewer-container {
    width: 100%;
    height: 100%;

}

.alert-container::before {
    content: "";
    position: absolute;
    top: -5px;
    left: -5px;
    right: -5px;
    bottom: -5px;
    background-image: linear-gradient(to right top, #ff5252, #f77b7b);
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
    z-index: -1;
}

.alert-container:hover::before,
.alert-container:focus::before {
    opacity: 1;
}

.hi{
    /* // height all page ; */
    height: 100vh;
    /* // width all page ; */
    /* width: 100vw; */

}