/* Folder style */
.folder {
  margin-left: 0px; /* Adjust indentation for nested folders */
  transition: max-height 0.3s ease;
  overflow: hidden;
}
.folderarrorw {fill:#bf105c}
/* Folder header style */
.folder-header {
  display: flex;
  align-items: center;
}

/* Folder and file icons */


.folder:first-child > .cursor-pointer  >  hr:first-of-type {
  display: none;
}


.icon {
  font-size: 20px;
}
.folder-list {
  transition: max-height 0.9s ease !important;
  overflow: hidden;
}
/* Expanded folder icon */
.fa-folder-open:before {
  content: "\f07c"; /* Unicode for open folder icon */
}
.slide-in {
  /* animation: slide-in 0.1s ease-in-out; */
}

.slide-out {
  /* animation: slide-out 0.1s ease-in-out; */
}
.d {
  background-color: red;
}
/* Works on Firefox */
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

@keyframes slide-in {
  from {
    opacity: 0;
    transform: translateY(-100%);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-out {
  from {
    opacity: 1;
    transform: translateY(0);
  }

  to {
    opacity: 0;
    transform: translateY(-100%);
  }
}

/* File style */
.file {
  margin-left: 20px; /* Adjust indentation for files */
  transition: max-height 0.9s ease !important;
  overflow: hidden;
}
/* FolderTree.css */

/* Define a style for the chart line */
.folder-chart-line {
  position: absolute;
  border-left: 2px solid #3498db; /* Adjust color as needed */
  height: 100%;
  top: 0;
  left: 12px; /* Adjust positioning based on your design */
}

/* Style the connector for the last folder in the hierarchy */
.folder:last-child .folder-chart-line {
  height: 50%; /* Adjust the height of the last connector */
}

/* Style the chart line when the folder is open */
.folder.opened .folder-chart-line {
  height: 100%;
}

/* Apply the style to the chart line within the folder */
.folder .folder-chart-line::before {
  content: "";
  position: absolute;
  border-top: 2px solid #3498db; /* Adjust color as needed */
  width: 12px;
  top: -8px; /* Adjust positioning based on your design */
  left: 0;
}

/* Style the last connector within an open folder */
.folder.opened .folder:last-child .folder-chart-line::before {
  height: 50%;
}

/* Apply styles for the chart line within a file */
.file .folder-chart-line {
  display: none; /* Hide chart lines for files */
}
